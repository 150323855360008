import React from 'react';
import clsx from 'clsx';

import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Typography } from '@mui/material';

import { GlobalContextModel } from 'api/models/general';
import { GlobalContext } from 'context/globalContext';

import { formatNumberToCurrencyString, numberStyles } from 'utils/formatNumber';

const useStyles = makeStyles((theme: Theme) => ({
    wrap: {
        padding: theme.spacing(2, 3),
        color: 'inherit',
        borderRight: `1px solid ${theme.palette.divider}`,

        '&:last-child': {
            borderRight: 'none',
        },
        [theme.breakpoints.down('sm')]: {
            borderRight: 'none',
            borderBottom: `1px solid ${theme.palette.divider}`,
            '&:last-child': {
                borderBottom: 'none',
            },
        }
    },
    spaceBetween: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    highlight: {
        color: theme.palette.primary.main,
    },
    title: {
        fontSize: '10px',
        textTransform: 'uppercase',
        fontWeight: 500,
    },
    value: {
        fontSize: '16px',
    },
    bold: {
        fontWeight: 'bold',
    },
}));

const Aggregations = ({
    title,
    value,
    count,
    highlight = false,
}: {
    title: string;
    value: number;
    count: number;
    highlight?: boolean;
}) => {
    const classes = useStyles({});
    const { globalSettings }: GlobalContextModel = React.useContext(GlobalContext);

    const wrapDataA = `aggregation-wrap-${highlight ? 'highlight' : 'default'}`;
    const titleReplacedDashes = title.replace(/\s+/g, '-').toLowerCase();

    return (
        <div className={clsx(classes.wrap, highlight && classes.highlight)} data-a={wrapDataA}>
            <Typography className={classes.title} data-a={`aggregation-${titleReplacedDashes}-title`}>{title}</Typography>
            <div className={classes.spaceBetween}>
                <span className={clsx(classes.value, classes.bold)} data-a={`aggregation-${titleReplacedDashes}-value`}>{
                    formatNumberToCurrencyString(
                        value,
                        numberStyles(globalSettings?.settings?.Currency),
                        globalSettings?.settings?.CurrencySign,
                        globalSettings?.settings?.CurrencyFormat
                    )}
                </span>
                <span className={classes.value} data-a={`aggregation-${titleReplacedDashes}-count`}>{count}</span>
            </div>
        </div>
    );
};

export default Aggregations;
