import { getIdFromObject, removeUndefinedEntries } from 'utils/objectMethods';
import {
    pickerDateToUtc,
    formatToIsoDateString,
} from 'utils/formatDate';
import { IFilterListItem, ORDER_BY_VOUCHERS_COLUMN, ORDER_BY_VOUCHERS_COLUMN_VALUES, VOUCHERS_STATUS, VOUCHERS_TYPE, ENTITY_TYPE, ORDER_BY_SUPERVISOR_APPROVALS_COLUMN, ORDER_BY_SUPERVISOR_APPROVALS_COLUMN_VALUES, ORDER_BY_CARD_TRANSACTIONS_COLUMN, ORDER_BY_CARD_TRANSACTIONS_COLUMN_VALUES } from 'const';
import Api from 'api/Api';
import {
    INetworkBetshop,
} from 'api/models/network-management';
import {
    ICardTransactionsFullSearchPayload,
    IGenericDateRange,
    ISupervisorApprovalsFullSearchPayload,
    ISupervisorsListModel,
    ITicketsFullSearchPayload,
    IVouchersFullSearchPayload,
    IFinancialPerformanceFullSearchPayload,
    IPointsOfSaleModel
} from 'api/models/reports';
import {
    ICardTransactionsReportFilterList,
    ISupervisorReportBaseFilterList,
    ISupervisorReportFilterList,
    ITicketsReportBaseFilterList,
    ITicketsReportFilterList,
    IVouchersReportBaseFilterList,
    IVouchersReportFilterList,
    IFinancialPerformanceReportFilterList,
    IFinancialPerformanceReportBaseFilterList,
} from 'pages/Reports/business';

type IWithBetshops = Record<'betShops', Array<IFilterListItem>>

export const getAllBetshops = async (
    apiRequests,
    setFiltersLists: React.Dispatch<React.SetStateAction<IWithBetshops>>,
    clientId
) => {
    apiRequests.getAllBetshops = Api.NetworkManagement.GetBetshopsLite();

    const result: Array<INetworkBetshop> = (await apiRequests.getAllBetshops)?.[clientId];
    const betShopItems = result
        .map(({ id, name }) => ({ id, name }))
        .sort((a, b) => a.name.localeCompare(b.name));

    setFiltersLists(
        (filtersLists) => <IWithBetshops>({
            ...filtersLists,
            betShops: betShopItems,
        })
    );

    return result.map<INetworkBetshop['id']>(getIdFromObject);
};

export const setAllFiltersListsData = async (
    apiRequests,
    setFiltersLists: React.Dispatch<React.SetStateAction<IVouchersReportBaseFilterList>>,
    clientId: string,
) => {
    apiRequests.getAllBetshops = Api.NetworkManagement.GetBetshopsLite();
    apiRequests.getEntities = Api.ReportFilters.GetEntities({ betShopIds: [], entityTypes: [ENTITY_TYPE.cashier, ENTITY_TYPE.terminal] });

    const result: Array<INetworkBetshop> = (await apiRequests.getAllBetshops)?.[clientId];
    const { pointsOfSale }: IPointsOfSaleModel = await apiRequests.getEntities;

    const betShopItems = result
        .map(({ id, name }) => ({ id, name }))
        .sort((a, b) => a.name.localeCompare(b.name));

    const pointsOfSaleItems: Array<IFilterListItem> = pointsOfSale
        .map(({ entityId: id, entityName: name, entityTypeId: type }) => ({ id, name, type }))
        .sort((a, b) => a.name.localeCompare(b.name));

    setFiltersLists(
        (filtersLists) => <IVouchersReportBaseFilterList>({
            ...filtersLists,
            issuedByEntityIds: [ ...pointsOfSaleItems ],
            issuedBetshopIds: [...betShopItems ],
            cashedOutByEntityIds: [ ...pointsOfSaleItems ],
            cashedOutBetshopIds: [...betShopItems ],
        })
    );

    return result.map<INetworkBetshop['id']>(getIdFromObject);
};

export const getPointsOfSale = async (
    apiRequests,
    setFiltersLists: React.Dispatch<React.SetStateAction<ITicketsReportBaseFilterList>>,
) => {
    apiRequests.getEntities = Api.ReportFilters.GetEntities({ betShopIds: [], entityTypes: [ENTITY_TYPE.cashier, ENTITY_TYPE.terminal] });

    const { pointsOfSale }: IPointsOfSaleModel = await apiRequests.getEntities;
    const entityItems: Array<IFilterListItem> = pointsOfSale
        .map(({ betShopId, entityId: id, entityName: name, entityTypeId: type }) => ({ betShopId, id, name, type }))
        .sort((a, b) => a.name.localeCompare(b.name));

    setFiltersLists(
        (filtersLists) => <ITicketsReportBaseFilterList>({
            ...filtersLists,
            pointsOfSale: entityItems,
        })
    );

    return entityItems;
};

export const generateActiveColumsForExcel = (activeColumns: any) => {
    let activeColumnsExcel: string[] = activeColumns.reduce((acc, key) =>{
        acc.push(key.id);

        return acc;
    }, [] as string[]);

    return activeColumnsExcel;
};

export const generateTicketsSearchPayload = <T = Date | string>(
    newFilters: Omit<ITicketsReportFilterList, keyof IGenericDateRange<Date>> & IGenericDateRange<T>,
    ticketsOnPage: number = 1,
    lastDateOnPage: string = null,
    lastSearchResultDate: string = null,
    activeColumns: string[] = null,
    isAscendingOrder: boolean = false,
    timeZone: string,
    ticketId?: string,
) => {
    const {
        searchByDate,
        fromDate,
        toDate,
        betShops,
        pointsOfSale,
        betStatuses,
        betTypes,
        payoutStatuses,
        overTicketCost,
        overWinningsAmount,
        overToPayAmount,
        overTotalPaidAmount,
    } = newFilters;

    const payload: ITicketsFullSearchPayload = {
        ticketId,
        isAscendingOrder,
        lastDateOnPage: formatToIsoDateString(lastDateOnPage),
        pageSize: ticketsOnPage,
        searchByDate,
        fromDate: (typeof fromDate === 'string')
            ? formatToIsoDateString(fromDate as unknown as string)
            : pickerDateToUtc(fromDate as unknown as Date, timeZone).toISOString(),
        toDate: (typeof toDate === 'string')
            ? formatToIsoDateString(toDate as unknown as string)
            : pickerDateToUtc(toDate as unknown as Date, timeZone).toISOString(),
        betShopIds: betShops.length ? betShops.map<number>(getIdFromObject) : [],
        pointOfSaleIds: pointsOfSale.map<number>(getIdFromObject),
        betStatuses: betStatuses.map<number>(getIdFromObject),
        betTypes: betTypes.map<number>(getIdFromObject),
        payoutStatuses: payoutStatuses.map<number>(getIdFromObject),
        overTicketCost: overTicketCost[0],
        overWinningsAmount: overWinningsAmount[0],
        overToPayAmount: overToPayAmount[0],
        overTotalPaidAmount: overTotalPaidAmount[0],
        lastSearchResultDate: formatToIsoDateString(lastSearchResultDate),
        activeColumns
    };

    return removeUndefinedEntries(payload) as ITicketsFullSearchPayload;
};

export const generateVouchersSearchPayload = <T = Date | string>(
    newFilters: Omit<IVouchersReportFilterList, keyof IGenericDateRange<Date>> & IGenericDateRange<T>,
    pageNumber: number = 0,
    ticketsOnPage: number = 0,
    isAscendingOrder: boolean = false,
    orderByColumn = ORDER_BY_VOUCHERS_COLUMN.issueDate,
    lastSearchResultDate: string = null,
    activeColumns: string[] = null,
    timeZone,
    showAggregations: boolean = true,
    voucherId?: string,
) => {
    const {
        voucherStatusIds,
        voucherTypeIds,
        issuedByEntityTypeIds,
        fromDate,
        toDate,
        issuedByEntityIds,
        issuedBetshopIds,
        cashedOutByEntityIds,
        cashedOutBetshopIds,
        minVoucherCost
    } = newFilters;

    const payload: IVouchersFullSearchPayload = {
        voucherId,
        isAscendingOrder,
        pageSize: ticketsOnPage,
        voucherStatusIds: voucherStatusIds.map((status) => VOUCHERS_STATUS[status.id]),
        voucherTypeIds: voucherTypeIds.map((type) => VOUCHERS_TYPE[type.id]),
        issuedByEntityTypeIds: issuedByEntityTypeIds.map(entity => ENTITY_TYPE[entity.id]),
        fromDate: (typeof fromDate === 'string')
            ? formatToIsoDateString(fromDate as unknown as string)
            : pickerDateToUtc(fromDate as unknown as Date, timeZone).toISOString(),
        toDate: (typeof toDate === 'string')
            ? formatToIsoDateString(toDate as unknown as string)
            : pickerDateToUtc(toDate as unknown as Date, timeZone).toISOString(),
        issuedByEntityIds: issuedByEntityIds.length ? issuedByEntityIds.map<number>(getIdFromObject) : [],
        issuedBetshopIds: issuedBetshopIds.length ? issuedBetshopIds.map<number>(getIdFromObject) : [],
        cashedOutByEntityIds: cashedOutByEntityIds.length ? cashedOutByEntityIds.map<number>(getIdFromObject) : [],
        cashedOutBetshopIds: cashedOutBetshopIds.length ? cashedOutBetshopIds.map<number>(getIdFromObject) : [],
        minVoucherCost: minVoucherCost[0] ?? 0,
        pageNumber: pageNumber + 1,
        lastSearchResultDate: formatToIsoDateString(lastSearchResultDate),
        orderByColumn: ORDER_BY_VOUCHERS_COLUMN_VALUES[orderByColumn],
        activeColumns: activeColumns,
        showAggregations
    };

    return removeUndefinedEntries(payload) as IVouchersFullSearchPayload;
};

export const getEntities = async (
    apiRequests,
    setFiltersLists: React.Dispatch<
    React.SetStateAction<IFinancialPerformanceReportBaseFilterList>
  >
) => {
    apiRequests.getEntities = Api.ReportFilters.GetEntities({
        betShopIds: [],
        entityTypes: [ENTITY_TYPE.cashier, ENTITY_TYPE.terminal],
    });

    const { pointsOfSale }: IPointsOfSaleModel = await apiRequests.getEntities;

    const entityItems: Array<IFilterListItem> = pointsOfSale
        .map(({ betShopId, entityId: id, entityName: name, entityTypeId: type }) => ({
            betShopId,
            id,
            name,
            type,
        }))
        .sort((a, b) => a.name.localeCompare(b.name));

    const cashiers: Array<IFilterListItem> = pointsOfSale
        .filter((item) => item.entityTypeId == ENTITY_TYPE.cashier)
        .map(({ betShopId, entityId: id, entityName: name, entityTypeId: type }) => ({
            betShopId,
            id,
            name,
            type,
        }))
        .sort((a, b) => a.name.localeCompare(b.name));

    const terminals: Array<IFilterListItem> = pointsOfSale
        .filter((item) => item.entityTypeId == ENTITY_TYPE.terminal)
        .map(({ betShopId, entityId: id, entityName: name, entityTypeId: type }) => ({
            betShopId,
            id,
            name,
            type,
        }))
        .sort((a, b) => a.name.localeCompare(b.name));

    setFiltersLists(
        (filtersLists) =>
      <IFinancialPerformanceReportBaseFilterList>{
          ...filtersLists,
          cashiers: cashiers,
          terminals: terminals,
      }
    );

    return entityItems;
};

export const getCashiersList = async (
    apiRequests,
    setFiltersLists: React.Dispatch<React.SetStateAction<IFinancialPerformanceReportBaseFilterList>>
) => {
    apiRequests.getEntities = Api.ReportFilters.GetEntities({ betShopIds: [], entityTypes: [ENTITY_TYPE.cashier] });

    const { pointsOfSale }: IPointsOfSaleModel = await apiRequests.getEntities;
    const cashiersListItems: Array<IFilterListItem> = pointsOfSale
        .map(({ betShopId, entityId: id, entityName: name }) => ({ betShopId, id, name }))
        .sort((a, b) => a.name.localeCompare(b.name));

    setFiltersLists(
        (filtersLists) => <IFinancialPerformanceReportBaseFilterList>({
            ...filtersLists,
            cashiers: cashiersListItems,
        })
    );

    return cashiersListItems;
};

export const getTerminalsList = async (
    apiRequests,
    setFiltersLists: React.Dispatch<React.SetStateAction<IFinancialPerformanceReportBaseFilterList>>
) => {
    apiRequests.getEntities = Api.ReportFilters.GetEntities({ betShopIds: [], entityTypes: [ENTITY_TYPE.terminal] });

    const { pointsOfSale }: IPointsOfSaleModel = await apiRequests.getEntities;
    const terminalsListItems: Array<IFilterListItem> = pointsOfSale
        .map(({ betShopId, entityId: id, entityName: name }) => ({ betShopId, id, name }))
        .sort((a, b) => a.name.localeCompare(b.name));

    setFiltersLists(
        (filtersLists) => <IFinancialPerformanceReportBaseFilterList>({
            ...filtersLists,
            terminals: terminalsListItems,
        })
    );

    return terminalsListItems;
};

export const generateCardTransactionsSearchPayload = <T = Date | string>(
    newFilters: Omit<ICardTransactionsReportFilterList, keyof IGenericDateRange<Date>> & IGenericDateRange<T>,
    pageNumber: number,
    approvalsOnPage: number,
    isAscendingOrder: boolean,
    orderByColumn = ORDER_BY_CARD_TRANSACTIONS_COLUMN.creationDate,
    timeZone: string,
    lastSearchResultDate?: Date | string,
    transactionRRNId?: string,
) => {
    const {
        fromDate,
        toDate,
        betShops,
        cashiers,
    } = newFilters;

    const payload: ICardTransactionsFullSearchPayload = {
        transactionRRNId,
        isAscendingOrder,
        pageNumber: pageNumber + 1,
        pageSize: approvalsOnPage,
        fromDate: (typeof fromDate === 'string')
            ? formatToIsoDateString(fromDate as unknown as string)
            : pickerDateToUtc(fromDate as unknown as Date, timeZone).toISOString(),
        toDate: (typeof toDate === 'string')
            ? formatToIsoDateString(toDate as unknown as string)
            : pickerDateToUtc(toDate as unknown as Date, timeZone).toISOString(),
        betShopIds: betShops.length ? betShops.map<number>(getIdFromObject) : [],
        issuerIds: cashiers.map<number>(getIdFromObject),
        lastSearchResultDate: formatToIsoDateString(lastSearchResultDate),
        orderByColumn: ORDER_BY_CARD_TRANSACTIONS_COLUMN_VALUES[orderByColumn],
    };

    return payload;
};

export const generateFinancialPerformanceSearchPayload = <T = Date | string>(
    newFilters: Omit<IFinancialPerformanceReportFilterList, keyof IGenericDateRange<Date>> & IGenericDateRange<T>,
    timeZone: string,
) => {
    const {
        fromDate,
        toDate,
        betShops,
        cashiers,
        terminals
    } = newFilters;

    const payload: IFinancialPerformanceFullSearchPayload = {
        fromDate: (typeof fromDate === 'string')
            ? formatToIsoDateString(fromDate as unknown as string)
            : pickerDateToUtc(fromDate as unknown as Date, timeZone).toISOString(),
        toDate: (typeof toDate === 'string')
            ? formatToIsoDateString(toDate as unknown as string)
            : pickerDateToUtc(toDate as unknown as Date, timeZone).toISOString(),
        betShopIds: betShops.length ? betShops.map<number>(getIdFromObject) : [],
        cashierIds: cashiers.map<number>(getIdFromObject),
        terminalIds: terminals.map<number>(getIdFromObject)
    };

    return payload;
};

export const getSupervisorsList = async (
    apiRequests,
    setFiltersLists: React.Dispatch<React.SetStateAction<ISupervisorReportBaseFilterList>>
) => {
    apiRequests.getSupervisorsList = Api.SupervisorReport.GetSupervisorsList({ betShopIds: [] });

    const { supervisorUsersList }: ISupervisorsListModel = await apiRequests.getSupervisorsList;
    const supervisorsListItems: Array<IFilterListItem> = supervisorUsersList
        .map(({ id, userName: name }) => ({ id, name }))
        .sort((a, b) => a.name.localeCompare(b.name));

    setFiltersLists(
        (filtersLists) => <ISupervisorReportBaseFilterList>({
            ...filtersLists,
            supervisors: supervisorsListItems,
        })
    );

    return supervisorsListItems;
};

export const generateApprovalsSearchPayload = <T = Date | string>(
    newFilters: Omit<ISupervisorReportFilterList, keyof IGenericDateRange<Date>> & IGenericDateRange<T>,
    pageNumber: number,
    approvalsOnPage: number,
    isAscendingOrder: boolean,
    orderByColumn = ORDER_BY_SUPERVISOR_APPROVALS_COLUMN.approvalDate,
    timeZone: string,
    lastSearchResultDate: string = null,
    documentId?: string,
) => {
    const {
        fromDate,
        toDate,
        betShops,
        supervisorApprovals,
        supervisors,
    } = newFilters;

    const payload: ISupervisorApprovalsFullSearchPayload = {
        documentId,
        isAscendingOrder,
        pageNumber: pageNumber + 1,
        pageSize: approvalsOnPage,
        fromDate: (typeof fromDate === 'string')
            ? formatToIsoDateString(fromDate as unknown as string)
            : pickerDateToUtc(fromDate as unknown as Date, timeZone).toISOString(),
        toDate: (typeof toDate === 'string')
            ? formatToIsoDateString(toDate as unknown as string)
            : pickerDateToUtc(toDate as unknown as Date, timeZone).toISOString(),
        betShopIds: betShops.length ? betShops.map<number>(getIdFromObject) : [],
        approvalTypes: supervisorApprovals.map<number>(getIdFromObject),
        supervisorIds: supervisors.map<number>(getIdFromObject),
        lastSearchResultDate: formatToIsoDateString(lastSearchResultDate),
        orderByColumn: ORDER_BY_SUPERVISOR_APPROVALS_COLUMN_VALUES[orderByColumn],

    };

    return payload;
};
